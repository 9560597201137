import React, {FC} from 'react';
import './index.scss';

export interface NavType {
  name: string;
  link: string;
}

export interface NavListPropsType {
  list: NavType[];
}


const Navigation:FC<NavListPropsType> = ({list}) => {
  return (
    <nav className="navigation">
      <ul className="navigation__list">
        {list.map((el) => (
          <li className="navigation__item">
            <a className="navigation__link" href={el.link}>{el.name}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
