import React from 'react';
import './index.scss';

import line from "../../../assets/Content/Partnership/line.svg";
import bubbles from "../../../assets/Content/Partnership/bubbles.png";
import bubbles2x from "../../../assets/Content/Partnership/bubbles@2x.png";
import bubblesWebp from "../../../assets/Content/Partnership/bubbles.webp";
import bubblesWebp2x from "../../../assets/Content/Partnership/bubbles@2x.webp";
import Modal from "../../Modal";

const Partnership = () => {
  return (
    <section className="partnership" id="business_model">
      <img className="partnership__line" src={line} alt="line"/>
      <div className="partnership__wrapper">
        <picture className="partnership__bubbles">
          <source srcSet={`${bubblesWebp} 1x, ${bubblesWebp2x} 2x`} type="image/webp"/>
          <img src={bubbles} srcSet={`${bubbles2x} 2x`} alt="Partnership advantages" width="515" height="356"/>
        </picture>
        <h2 className="partnership__title">
          Partnership
        </h2>
        <p className="partnership__content">

          Do you teach or hold activities for children? Do you have a family cafe or playground?
          Do you have a kindergarten or school? Are you looking for a way to spread the word about yourself and your services? <br/>
          Leave your contact information below and we'll show and tell you how to do it using our website

        </p>
        <div className="partnership__btn-wrapper">
          <Modal/>
        </div>
      </div>
    </section>
  );
};

export default Partnership;
