import React from 'react';
import './index.scss';

import linkedin from "../../assets/Social/linkedin.svg";
import telegram from "../../assets/Social/telegram.svg";
import whatsapp from "../../assets/Social/whatsapp.svg";
import instagram from "../../assets/Social/instagram.svg";
import facebook from "../../assets/Social/facebook.svg";

const Social = () => {
  return (
    <div className="social">
      <a className="social__link" href='http://wa.me/6281239015349'>
        <img className="social__image" width="18" height="18" src={whatsapp} alt="whatsapp"/>
      </a>
      <a className="social__link" href='https://www.instagram.com/kidspaceindonesia/'>
        <img className="social__image" width="16" height="16" src={instagram} alt="instagram"/>
      </a>
      <a className="social__link" href='https://www.facebook.com/kidspace.id/'>
        <img className="social__image" width="18" height="17" src={facebook} alt="facebook"/>
      </a>
       <a className="social__link" href='https://t.me/kidspaces_bot'>
        <img className="social__image" width="18" height="16" src={telegram} alt="telegram"/>
      </a>
       <a className="social__link" href='https://www.linkedin.com/company/kidspace-indonesia'>
        <img className="social__image" width="17" height="16" src={linkedin} alt="linkedin"/>
      </a>
    </div>
  );
};

export default Social;
