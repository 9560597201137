import React from 'react';
import {About} from './About';
import {Features} from './Features';
import Bali from "./Bali";
import Jakarta from "./Jakarta";
import OurApp from "./OurApp";
import Partnership from "./Partnership";
import Questions from "./Questions";
import './index.scss';

export const Content = () => {
  return (
    <div className="content">
      <About/>
      <Features/>
      <Bali/>
      <Jakarta/>
      <OurApp/>
      <Partnership/>
      <Questions/>
    </div>
  );
};
