import React from 'react';
import './index.scss';

import line from "../../../assets/Content/Bali/line.svg";

const Bali = () => {
  return (
    <section className="bali" id="bali">
      <img className="bali__line" src={line} alt="line"/>
      <div className="bali__wrapper">
        <h2 className="bali__title">
          Bali
        </h2>
        <p className="bali__content">
          More than 380 kid-friendly places selected in Bali:<br className="br-desktop"/>
          schools, kindergartens, classes, shops,<br className="br-desktop"/>
          playgrounds and events.<br/>
          Already available!
        </p>
        <a href="https://providers.kidspace.id" className="bali__link button" target="_blank">
          Try it!
        </a>
      </div>
    </section>
  );
};

export default Bali;
