import React from 'react';
import './index.scss';
import Navigation, {NavType} from "../Navigation";
import Social from "../Social";

import logo from 'assets/Header/logo.png';
import logo2x from 'assets/Header/logo@2x.png';
import logoWebp from 'assets/Header/logo.webp';
import logoWebp2x from 'assets/Header/logo@2x.webp';


const headerNav: NavType[] = [
  {
    name: 'Browse',
    link: 'https://providers.kidspace.id/',
  },
  {
    name: 'Blog',
    link: 'https://providers.kidspace.id/blog',
  },
]


export const Header = () => {
  return (
    <header className="header">
      <a className="header__logo" href="/">
        <picture className="header__image">
          <source srcSet={`${logoWebp} 1x, ${logoWebp2x} 2x`} type="image/webp"/>
          <img src={logo} srcSet={`${logo2x} 2x`} alt="Kidspace Logo" width="93" height="93"/>
        </picture>
      </a>
      <Navigation list={headerNav}/>
      <Social/>
    </header>
  );
};
