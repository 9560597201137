import React from 'react';
import {Header} from './Components/Header';
import {Footer} from './Components/Footer';
import {Content} from './Components/Content';

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Header/>
        <Content/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
