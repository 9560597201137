import React from 'react';
import EmailForm from "../../EmailForm";
import './index.scss';

import line from "../../../assets/Content/Jakarta/roundLine.svg";

const Jakarta = () => {
  return (
    <section className="jakarta">
      <img className="jakarta__line" src={line} alt="line"/>
      <div className="jakarta__wrapper">
        <h2 className="jakarta__title">
          Jakarta
        </h2>
        <p className="jakarta__content">
          We have already started collecting the best places and activities for your kids in Jakarta. 
          We are now in the process of developing a website where all the information will be posted! <br/>
          Leave your email address and we'll get back to you when the site goes live
        </p>
        <div className="jakarta__form-wrapper">
          <EmailForm buttonName="Stay in touch"/>
        </div>
      </div>
    </section>
  );
};

export default Jakarta;
