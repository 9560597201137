import React from 'react';
import './index.scss';
import Navigation, {NavType} from "../Navigation";
import Social from "../Social";

import logo from 'assets/Header/logo.png';
import logoWebp from "../../assets/Header/logo.webp";
import logoWebp2x from "../../assets/Header/logo@2x.webp";
import logo2x from "../../assets/Header/logo@2x.png";

const footerNav: NavType[] = [
  {
    name: 'About',
    link: 'https://providers.kidspace.id/about',
  },
  {
    name: 'Browse',
    link: 'https://providers.kidspace.id/',
  },
  {
    name: 'Blog',
    link: 'https://providers.kidspace.id/blog',
  },
  {
    name: 'For investors',
    link: 'https://kidspace-id.notion.site/Kidspace-product-market-money-team-vision-b24d0ee04275432c9c0721df6a45b37a',
  },
  {
    name: 'Terms & Conditions',
    link: 'https://providers.kidspace.id/terms',
  },
  {
    name: 'Privacy',
    link: 'https://providers.kidspace.id/privacy',
  },
]

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__main">
        <a className="footer__logo" href="/">
          <picture className="footer__image">
            <source srcSet={`${logoWebp} 1x, ${logoWebp2x} 2x`} type="image/webp"/>
            <img src={logo} srcSet={`${logo2x} 2x`} alt="Kidspace Logo" width="93" height="93"/>
          </picture>
        </a>
        <Navigation list={footerNav}/>
        <Social/>
      </div>
      <div className="copyright">
        <p className="copyright__content">© {new Date().getFullYear()} Sola Lab Ltd. All
          rights reserved</p>
      </div>
    </footer>
  );
};
