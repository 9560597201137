import React from 'react';
import EmailForm from "../../EmailForm";
import './index.scss';

import line from "../../../assets/Content/OurApp/line.svg";
import appInterface from "../../../assets/Content/OurApp/interface.png";
import appInterface2x from "../../../assets/Content/OurApp/interface@2x.png";
import appInterfaceWebp from "../../../assets/Content/OurApp/interface.webp";
import appInterfaceWebp2x from "../../../assets/Content/OurApp/interface@2x.webp";

const OurApp = () => {
  return (
    <section className="our-app">
      <img className="our-app__line" src={line} alt="line"/>
      <div className="our-app__wrapper">

        <picture className="our-app__interface">
          <source srcSet={`${appInterfaceWebp} 1x, ${appInterfaceWebp2x} 2x`} type="image/webp"/>
          <img src={appInterface} srcSet={`${appInterface2x} 2x`} alt="App Interface" width="834" height="569"/>
        </picture>

        <h2 className="our-app__title">
          App and Website
        </h2>
        <p className="our-app__content">

          The Kidspace app will launch sometime later.<br/>
          Leave your email and we'll email you as soon as the app is up and running

        </p>
        <div className="our-app__form-wrapper">
          <EmailForm buttonName="I’m in"/>
        </div>
      </div>
    </section>
  );
};

export default OurApp;
