import React, {useState} from 'react';
import Popup from "reactjs-popup";
import './index.scss';
import {Form, Formik, FormikErrors} from "formik";

interface FormValues {
  phone: string;
  name: string;
  category: string | null;
}

const send = (values: string) => {
  console.log(values)
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: values,
  };

  fetch("https://api.kidspace.id/partnership", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}

const Modal = () => {

  const closeHandler = (close: () => void) => {
    setCategory(null);
    setSuccess(false)
    close();
  }

  const [success, setSuccess] = useState<boolean>(false);
  const [category, setCategory] = useState<null | string>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const options = ["Studios", "Courses", "Shops", "City events", "Playgrounds & cafes", "Kindergartens & schools"];

  const arrow = isOpen ? 'arrow__up' : 'arrow__down';

  return <Popup
    trigger={<button className="partnership__btn button">Become a partner</button>}
    modal
    nested
    lockScroll={false}
    closeOnEscape={true}
  >
    {(close: () => void) => (
      <div className="modal">
        <button
          className="modal__close"
          type="button"
          onClick={() => closeHandler(close)}>
        </button>

        {!success
          ? <>
            <h2 className="modal__title">Let's get to know<br className="br-mobile"/> each other better</h2>
            <p className="modal__content">
              Tell us a little more about your company<br/>
              and we will contact you
            </p>

            <Formik
              initialValues={{phone: '', name: '', category: ''}}
              validate={(values: FormValues) => {

                const errors: FormikErrors<FormValues> = {};

                if (!values.phone) {
                  errors.phone = 'Fill in the field';
                } else if (!/^\+?([0-9]|\s|-)*$/i.test(values.phone)) {
                  errors.phone = 'Incorrect. Use only numbers';
                }

                if (!values.name) {
                  errors.name = 'Fill in the field';
                } else if (values.name.length > 20) {
                  errors.name = 'Must be 20 characters or less';
                }

                if (!values.category) {
                  errors.category = 'Choose a category';
                }

                return errors;
              }}
              onSubmit={(values, actions) => {
                setSuccess(true);
                send(JSON.stringify(values, null, 2))
                actions.resetForm()
              }}
            >{formik => (
              <Form className="modal__form">
                <input
                  className={`modal__input ${formik.errors.name ? 'errorInput' : ''}`}
                  type="text"
                  placeholder="Name of your company"
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name ?
                  <div className="modal__error">{formik.errors.name}</div> : null}

                <div tabIndex={0}
                     className={`options__wrapper ${isOpen ? "isOpened" : ""} ${formik.errors.category ? 'errorInput' : ''}`}>
                  <div
                    className={`options__header ${category !== null ? 'options__header-selected' : ''}`}
                    onClick={toggling}
                  >
                    {category || 'Choose category'}
                    <div
                      className={`options__arrow ${arrow}`}
                      onClick={toggling}
                    ></div>
                  </div>
                  {isOpen && (
                    <ul className="options__list">
                      <div
                        className={`options__arrow ${arrow}`}
                        onClick={toggling}
                      ></div>
                      {options.map(option => (
                        <li className="options__item"
                            onClick={() => {
                              setCategory(option);
                              setIsOpen(false);
                              formik.setFieldValue('category', option);
                            }}
                            key={Math.random()}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {formik.touched.category && formik.errors.category ?
                  <div className="modal__error">{formik.errors.category}</div> : null}

                <input
                  className={`modal__input ${formik.errors.phone ? 'errorInput' : ''}`}
                  type="text"
                  maxLength={20}
                  placeholder="Phone number"
                  {...formik.getFieldProps('phone')}
                />

                {formik.touched.phone && formik.errors.phone ?
                  <div className="modal__error">{formik.errors.phone}</div> : null}

                <button className="button modal__send" type="submit">
                  Send
                </button>
              </Form>
            )}
            </Formik>

          </>
          : <>
            <h2 className="modal__title">Thank you!</h2>
            <p className="modal__content">

              We will contact you as soon as possible to discuss the details

            </p>
            <button
              className="button modal__success-btn"
              type="button"
              onClick={() => {
                closeHandler(close)
              }}
            >
              That's great!
            </button>
          </>}
      </div>
    )}
  </Popup>
};

export default Modal;
