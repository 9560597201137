import React from 'react';
import './index.scss';

import blueBg from 'assets/Content/About/blueBg.png';
import blueBg2x from 'assets/Content/About/blueBg@2x.png';
import blueBgWebp from 'assets/Content/About/blueBg.webp';
import blueBgWebp2x from 'assets/Content/About/blueBg@2x.webp';
import pinkBg from 'assets/Content/About/pinkBg.png';
import pinkBg2x from 'assets/Content/About/pinkBg@2x.png';
import pinkBgWebp from 'assets/Content/About/pinkBg.webp';
import pinkBgWebp2x from 'assets/Content/About/pinkBg@2x.webp';
import yellowBg from 'assets/Content/About/yellowBg.png';
import yellowBg2x from 'assets/Content/About/yellowBg@2x.png';
import yellowBgWebp from 'assets/Content/About/yellowBg.webp';
import yellowBgWebp2x from 'assets/Content/About/yellowBg@2x.webp';
import line from 'assets/Content/About/line.svg';

export const About = () => {
  return (
    <>
      <AboutHeader/>
      <AboutMain/>
    </>
  )
}

const AboutHeader = () => {
  return (
    <section className="about-header">
      <div className="about-header__wrapper">
        <div className="about-header__side">
          <h1 className="about-header__title">
            We help you to find
            the most relevant
            activities
            for your kids
          </h1>
          <p className="about-header__content">
            lot of kindergartens, schools, classes and places
            for parents with children in Indonesia
          </p>
          <a href="https://providers.kidspace.id" className="about-header__link button">
            Get started for free
          </a>
        </div>

        {/* add animation to blueBg div tp change periodically blueBg, pinkBg and yellowBg images  */}
        <div className="blueBg">

          <picture className="about__frame1">
            <source srcSet={`${blueBgWebp} 1x, ${blueBgWebp2x} 2x`}
                    type="image/webp"/>
            <img src={blueBg} className="about__image1"
                 srcSet={`${blueBg2x} 2x`} alt="welcome image" width="693" height="677"/>
          </picture>

          <picture className="about__frame2">
            <source srcSet={`${pinkBgWebp} 1x, ${pinkBgWebp2x} 2x`} type="image/webp"/>
            <img src={pinkBg} srcSet={`${pinkBg2x} 2x`} className="about__image2" alt="welcome image" width="541" height="661"/>
          </picture>

          <picture className="about__frame3">
            <source srcSet={`${yellowBgWebp} 1x, ${yellowBgWebp2x} 2x`} type="image/webp"/>
            <img src={yellowBg} srcSet={`${yellowBg2x} 2x`} className="about__image3" alt="welcome image" width="519" height="658"/>
          </picture>
        </div>
      </div>
    </section>
  );
};

const AboutMain = () => {
  return (
    <section className="about-main" id="about">
      <img src={line} className="about__line" alt="line"/>
      <div className="about-main__wrapper">
        <h2 className="about-main__title">About KidSpace</h2>
        <p className="about-main__content">

          An application and website for parents who want to organize
          their children's leisure time, find educational courses,
          keep up with city events, as well as meet other parents
          and attend various events together

        </p>
        <dl className="about-main__list">
          <dt className="list__title">
            Ratings
          </dt>
          <dd className="list__description">
            We collect reviews of all places to make it easier for you to make the right decision
          </dd>
          <dt className="list__title">
            Convenience
          </dt>
          <dd className="list__description">
            Simple and clear search will save time on finding the perfect place
          </dd>
          <dt className="list__title">
            Completeness
          </dt>
          <dd className="list__description">
            Detailed information: addresses, photos, services, prices and all links
          </dd>
        </dl>
      </div>
    </section>
  )
}
