import React from 'react';
import './index.scss';
import parse from 'html-react-parser';

import studios from 'assets/Content/Features/studios.png';
import studios2x from 'assets/Content/Features/studios@2x.png';
import studiosWebp from 'assets/Content/Features/studios.webp';
import studiosWebp2x from 'assets/Content/Features/studios@2x.webp';
import cityEvents from 'assets/Content/Features/city-events.png';
import cityEvents2x from 'assets/Content/Features/city-events@2x.png';
import cityEventsWebp from 'assets/Content/Features/city-events.webp';
import cityEventsWebp2x from 'assets/Content/Features/city-events@2x.webp';
import communication from 'assets/Content/Features/communication.png';
import communication2x from 'assets/Content/Features/communication@2x.png';
import communicationWebp from 'assets/Content/Features/communication.webp';
import communicationWebp2x from 'assets/Content/Features/communication@2x.webp';
import playgroundsCafes from 'assets/Content/Features/playgrounds-cafes.png';
import playgroundsCafes2x from 'assets/Content/Features/playgrounds-cafes@2x.png';
import playgroundsCafesWebp from 'assets/Content/Features/playgrounds-cafes.webp';
import playgroundsCafesWebp2x from 'assets/Content/Features/playgrounds-cafes@2x.webp';
import shops from 'assets/Content/Features/shops.png';
import shops2x from 'assets/Content/Features/shops@2x.png';
import shopsWebp from 'assets/Content/Features/shops.webp';
import shopsWebp2x from 'assets/Content/Features/shops@2x.webp';
import kindergartensSchools from 'assets/Content/Features/kindergarten-schools.png';
import kindergartensSchools2x from 'assets/Content/Features/kindergarten-schools@2x.png';
import kindergartensSchoolsWebp from 'assets/Content/Features/kindergarten-schools.webp';
import kindergartensSchoolsWebp2x from 'assets/Content/Features/kindergarten-schools@2x.webp';
import line from 'assets/Content/Features/triangleLine.svg';

type FeaturesType = {
  id: number
  title: string
  text: string
  imgPng: string
  imgPng2x: string
  imgWebp: string
  imgWebp2x: string
}

export const Features = () => {

  const features: FeaturesType[] = [
    {
      id: 1,
      title: 'Classes',
      text: 'More than 100 kinds of classes: from dance studios to design and IT courses',
      imgPng: studios,
      imgPng2x: studios2x,
      imgWebp: studiosWebp,
      imgWebp2x: studiosWebp2x
    },
    {
      id: 2,
      title: 'City events',
      text: 'All city events that would be interesting to visit<br class="br-desktop"/> with a child',
      imgPng: cityEvents,
      imgPng2x: cityEvents2x,
      imgWebp: cityEventsWebp,
      imgWebp2x: cityEventsWebp2x
    },
    {
      id: 3,
      title: 'Communication',
      text: 'Making new friends for yourself and your children will become much easier with our service',
      imgPng: communication,
      imgPng2x: communication2x,
      imgWebp: communicationWebp,
      imgWebp2x: communicationWebp2x
    },
    {
      id: 4,
      title: 'Playgrounds<br class="br-desktop"/> & cafes',
      text: 'Selecting the most<br class="br-desktop"/> diverse places to relax<br class="br-desktop"/> with your child',
      imgPng: playgroundsCafes,
      imgPng2x: playgroundsCafes2x,
      imgWebp: playgroundsCafesWebp,
      imgWebp2x: playgroundsCafesWebp2x
    },
    {
      id: 5,
      title: 'Shops',
      text: 'The best stores with a large selection of goods, gifts and clothing',
      imgPng: shops,
      imgPng2x: shops2x,
      imgWebp: shopsWebp,
      imgWebp2x: shopsWebp2x
    },
    {
      id: 6,
      title: 'Kindergartens<br class="br-desktop"/> & schools',
      text: 'Verified organizations that offer a wide range of educational services',
      imgPng: kindergartensSchools,
      imgPng2x: kindergartensSchools2x,
      imgWebp: kindergartensSchoolsWebp,
      imgWebp2x: kindergartensSchoolsWebp2x
    },
  ]

  return (
    <section className="features" >
      <img className="features__line" src={line} alt="Line"/>
      <div className="features__wrapper" id="features">
        <h2 className="features__title">
          A wide variety of activities
        </h2>
        <ul className="features__list">
          {features.map(feature => {
            return <li className="features__item" key={feature.id}>
              <picture className="feature__image">
                <source
                  srcSet={`${feature.imgWebp} 1x, ${feature.imgWebp2x} 2x`}
                  type="image/webp"
                />
                <img
                  src={feature.imgPng}
                  srcSet={`${feature.imgPng2x} 2x`}
                  width="160" height="160" alt="feature image"
                />
              </picture>
              <h3 className="feature__title">{parse(feature.title)}</h3>
              <p className="feature__text">{parse(feature.text)}</p>
            </li>
          })}
        </ul>
      </div>
    </section>
  );
};
