import React, {useState} from 'react';
import './index.scss';

import line from "../../../assets/Content/Questions/squareLine.svg";

type QuestionType = {
  id: string
  title: string
  content: string
}

type QuestionPropsType = Omit<QuestionType, 'id'>


const Questions = () => {

  const questions: QuestionType[] = [
    {
      id: '1',
      title: 'What is Kidspace? Do you provide any activities for kids?',
      content: 'Kidspace is a children\'s activity marketplace in Indonesia. This is the place where you can find a classes for children, find a cafe with playground or see lists of upcoming children\'s events'
    },
    {
      id: '2',
      title: 'Who is this site for?',
      content: 'Kidspace is made for parents to help them quickly find the best educational and entertainment activities'
    },
    {
      id: '3',
      title: 'Have you already launched the application and website?',
      content: 'Not yet. Right now we only have a chatbot that collects all the children\'s places in Bali. But very soon there will be a website and an application!'
    },
    {
      id: '4',
      title: 'What cities are you planning to launch in?',
      content: 'We are launching a full functional website in the first quarter of 2023 for Bali and Jakarta. During the next year, we plan to start working in other cities in Indonesia'
    },
    {
      id: '5',
      title: 'Will the website and app be in Indonesian?',
      content: 'Yes, both the site and app will support English and Indonesian'
    },
    {
      id: '6',
      title: 'Is it all free?',
      content: 'Yes, the use of the site and the application will be free'
    },
    {
      id: '7',
      title: 'I want to become your partner and place information about my services on your website. What do I need to do for that?',
      content: 'Email us at: kidspace.id@gmail.com'
    },
  ]

  return (
    <section className="questions">
      <img className="questions__line" src={line} alt="line"/>
      <div className="questions__wrapper">
        <h2 className="questions__title">
          Do you have any questions?
        </h2>
        <div className="questions__list">
          {questions.map(q => <Question title={q.title} content={q.content} key={q.id}/>)}
        </div>
      </div>
    </section>
  );
};

const Question: React.FC<QuestionPropsType> = (props) => {
  const [isActive, setIsActive] = useState(false);

  const arrow = isActive ? 'arrow__up' : 'arrow__down';
  const titleOpened = isActive ? 'titleOpened' : '';

  return (
    <div className="question">
      <div className="question__wrapper">
        <div
          className={`question__title ${titleOpened}`}
          onClick={() => setIsActive(!isActive)}
        >
          {props.title}</div>
        <div
          className={`question__arrow ${arrow}`}
          onClick={() => setIsActive(!isActive)}
        ></div>
      </div>
      {isActive && <div className="question__content">{props.content}</div>}
    </div>
  )
}

export default Questions;
