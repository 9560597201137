import React from 'react';
import './index.scss';
import {useFormik, FormikErrors} from "formik";
import Popup from "reactjs-popup";

interface FormValues {
  email: string;
}

type EmailFormPropsType = {
  buttonName: string
}

const send = (mail: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: mail,
  };

  fetch(`https://api.kidspace.id/email-sent`, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}

const EmailForm = (props: EmailFormPropsType) => {

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = 'Fill in the field';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
    onSubmit: values => {
      send(JSON.stringify(values, null, 2));
      formik.resetForm();
    },
  });

  const errorClass = formik.errors.email ? 'errorActive' : '';

  return (
    <form className="emailForm" onSubmit={formik.handleSubmit}>
      <input
        className={`emailForm__input ${errorClass}`}
        type="text"
        placeholder="Type your e-mail"
        {...formik.getFieldProps('email')}
      />
      {formik.touched.email && formik.errors.email ?
        <div className="emailForm__error">{formik.errors.email}</div> : null}

      {formik.errors.email
        ? <button
          className="emailForm__btn button"
          type="submit"
        >
          {props.buttonName}
        </button>
        : <Popup
          trigger={<button
            className="emailForm__btn button"
            type="submit"
          >
            {props.buttonName}
          </button>}
          modal
          nested
          lockScroll={false}
          closeOnEscape={true}
        >
          {(close: () => void) => (
            <div className="modal">
              <button
                className="modal__close"
                type="button"
                onClick={close}>
              </button>
              <h2 className="modal__title">Thank you<br className="br-mobile"/> for subscribing!</h2>
              <p className="modal__content">
                We will inform you only important news<br/> and updates of our service
              </p>
              <button
                className="button modal__success-btn"
                type="button"
                onClick={close}
              >
                That’s great!
              </button>
            </div>
          )}
        </Popup>
      }
    </form>
  );
};

export default EmailForm;
